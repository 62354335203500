import styled, { css } from 'styled-components'
import Image from './Image'

const hidden = css`
  opacity: 0;
`

const ScrollToTopButton = styled.a`
  background-color: black;
  position: fixed;
  bottom: 60px;
  right: 60px;
  width: 42px;
  height: 42px;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  transition: 1s opacity;
  opacity: 1;
  ${props => props.hidden && hidden}
`
ScrollToTopButton.Image = Image

export default ScrollToTopButton
