import styled from 'styled-components'

const Item = styled.li`
  display: block;
  padding: 0.25rem 1.5rem;
  font-weight: 400;
  color: #212529;
  text-decoration: none;
  &:hover {
    background-color: #f8f9fa;
    color: #f1574d !important;
    outline: none;
    text-decoration: none;
  }
`

export default Item
