import styled from 'styled-components'
import React from 'react'

const Menu = styled.div`
  position: absolute;
  left: 35px;
  top: 45px;
  cursor: pointer;
  display: none;

  @media screen and (max-width: 765px) {
    display: block;
  }
`
const Bar = styled.div`
  height: 2px;
  width: 30px;
  margin: 4px 0;
  display: block;
  background-color: #000;
`

const HamburgerMenu = props => (
  <Menu visible={props.visible}>
    <Bar />
    <Bar />
    <Bar />
  </Menu>
)

export default HamburgerMenu
