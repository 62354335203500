import styled from 'styled-components'
import Item from './Item'
import StyledLink from './StyledLink'

const FooterSocial = styled.ol`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 22px;
  font-family: 'Merriweather', serif;
  padding: 57px 0 66px;
`
FooterSocial.Item = Item
FooterSocial.StyledLink = StyledLink

export default FooterSocial
