import styled from 'styled-components'
import Button from './Button'
import Nav from './Nav'
import FlatButton from './FlatButton'

const NavigationMenu = styled.div`
  line-height: 131%;
  position: absolute;
  bottom: 0;
  width: 90%;
  transition: border-bottom 0s, all 0.4s;
  font-size: 14px;
  font-family: Montserrat, sans-serif !important;
  font-weight: 400;
  @media screen and (max-width: 768px) {
    display: none;
  }
`
NavigationMenu.Nav = Nav
NavigationMenu.Button = Button
NavigationMenu.FlatButton = FlatButton

export default NavigationMenu
