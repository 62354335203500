import styled from 'styled-components'

const FlatButton = styled.a`
  text-decoration: none;
  color: white;
  transition: all 0.4s ease;
  
  position: relative;
  background: #3f7a68;
  border-radius: 4px;
  margin-top:15px;

  display: block;
  padding: 10px 20px !important;
  transition: 0.3s;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`

export default FlatButton
