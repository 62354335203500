import { Link } from 'gatsby'
import React from 'react'
import footerBackgroundImage from '../../../assets/images/footer_bg.jpg'
import Logo from '../../../assets/images/logo.png'
import Footer from '../../blocks/Footer'
import FooterList from '../../blocks/FooterList'
import FooterSocial from '../../blocks/FooterSocial'

const FooterFragment = () => (
  <Footer>
    <Footer.Image
      className='lazyload'
      src={footerBackgroundImage}
      alt='Background image'
    />
    <Footer.Container>
      <Footer.Column>
        <Footer.Logo className='lazyload' src={Logo} alt='Amber Living Logo' height='50'/>
        <FooterSocial>
          <FooterSocial.Item>
            <FooterSocial.StyledLink href='https://www.instagram.com/amber_living.de/'>
              Instagram
            </FooterSocial.StyledLink>
          </FooterSocial.Item>
          <FooterSocial.Item>
            <FooterSocial.StyledLink href='https://www.pinterest.de/amber_living/'>
              Pinterest
            </FooterSocial.StyledLink>
          </FooterSocial.Item>
          <FooterSocial.Item>
            <FooterSocial.StyledLink href='https://www.linkedin.com/company/media-juice-berlin'>
              Linkedin
            </FooterSocial.StyledLink>
          </FooterSocial.Item>
        </FooterSocial>
        <FooterList>
          <FooterList.Item>
            <FooterList.StyledHeader>Über amber living</FooterList.StyledHeader>
          </FooterList.Item>
          <FooterList.Item>
            <FooterList.StyledLink href='/blog'>Blog</FooterList.StyledLink>
          </FooterList.Item>
          <FooterList.Item>
            <FooterList.StyledLink as={Link} to='/about'>
              ÜBER UNSS
            </FooterList.StyledLink>
          </FooterList.Item>
          <FooterList.Item>
            <FooterList.StyledLink
              as={Link}
              to='/nutzungsbedingungen-und-datenschutz'
            >
              NUTZUNGSBEDINGUNGEN & DATENSCHUTZ
            </FooterList.StyledLink>
          </FooterList.Item>
          <FooterList.Item>
            <FooterList.StyledLink as={Link} to='/impressum'>
              IMPRESSUM
            </FooterList.StyledLink>
          </FooterList.Item>
        </FooterList>
      </Footer.Column>
      <Footer.Text>
        Copyright © 2019 Media Juice UG. Alle Rechte vorbehalten. Stillvol
        Wohnen.
      </Footer.Text>
    </Footer.Container>
  </Footer>
)

export default FooterFragment
