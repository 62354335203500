import styled from 'styled-components'
import NavigationDropdown from '../NavigationDropdown'

const Item = styled.li`
  position: relative;
  text-transform: capitalize;

  &:last-child ${NavigationDropdown} {
    right: 0;
  }

  &:hover {
    color: #f1574d !important;
    outline: none;
    text-decoration: none;
  }

  &:hover ${NavigationDropdown} {
    display: block;
  }
`
export default Item
