import styled from 'styled-components'

const Item = styled.li`
  display: grid;
  padding-bottom: 15px;
  margin: 5px;
  justify-content: center;
`

export default Item
