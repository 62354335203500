import styled from 'styled-components'
import Item from './Item'

const NavigationBar = styled.ol`
  position: relative;
  display: grid;
  list-style: none;
  grid-row: 1;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
  grid-auto-flow: column;
  grid-gap: 10px;

  &:before {
    content: '';
    width: 150%;
    max-width: calc(100vw - 10px);
    left: -25%;
    height: 1px;
    position: absolute;
    bottom: 0;
    border-bottom: 1px solid #e5e5e5;
  }
`

NavigationBar.Item = Item

export default NavigationBar
