import styled from 'styled-components'
import Item from './Item'
import StyledLink from './StyledLink'

const NavigationDropdown = styled.ol`
  position: absolute;
  top: 100%;
  background: #fff;
  margin: 0;
  padding: 20px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.14);
  line-height: 30px;
  display: none;
  z-index: 10;
`

NavigationDropdown.Item = Item
NavigationDropdown.StyledLink = StyledLink

export default NavigationDropdown
