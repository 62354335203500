import styled from 'styled-components'

const Button = styled.a`
  text-decoration: none;
  color: inherit;
  transition: all 0.4s ease;
  position: relative;
  display: block;
  padding: 24px 20px !important;
  &:hover {
    cursor: pointer;
  }

  > svg {
    display: none;
    position: absolute;
    font-family: FontAwesome, sans-serif;
    font-size: 14px;
    left: 0;
    right: 0;
    margin: 20px auto 0;
    text-align: center;
    color: #f1574d;
  }

  &:hover > svg {
    display: ${props => (props.href ? 'none' : 'inline')};
  }
`

export default Button
