import 'reset-css'
import { createGlobalStyle } from 'styled-components'

import slickTheme from './slick-theme'
import Fonts from '../../assets/fonts'

const GlobalStyle = createGlobalStyle`
  ${slickTheme};

  @font-face {
    font-family: 'FontAwesome';
    font-style: normal;
    font-weight: 400;
    src: url(${Fonts.FontAwesomeOTF}) format('woff2');
    font-display: swap;
  }
  
  @keyframes dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes fadeInDown {
    0% {
      transform: translateY(-80px);
    }
  
    100% {
      transform: translateY(0);
    }
  }
  
  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translateY(10%);
    }
  
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @media screen and (max-width: 768px) {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
  
    &::-webkit-scrollbar {
      width: 1px;
    }
  
    &::-webkit-scrollbar-track {
      background: transparent;
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }
  }
  
  body {
    font-family: Montserrat, sans-serif;
  }
  
  * {
    box-sizing: border-box;
  }
`

export default GlobalStyle
