import styled from 'styled-components'
import Column from './Column'
import Container from './Container'
import Image from './Image'
import Logo from './Logo'
import Text from './Text'

const Footer = styled.footer`
  padding: 90px 0;
  position: relative;
`

Footer.Container = Container
Footer.Column = Column
Footer.Text = Text
Footer.Logo = Logo
Footer.Image = Image

export default Footer
