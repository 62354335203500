import styled from 'styled-components'

const StyledLink = styled.a`
  color: #999999;
  font-family: Merriweather, serif;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  letter-spacing: 0.48px;
  text-transform: uppercase;
  text-decoration: none;
  transition: all 700ms ease;

  &:hover {
    color: #f1574d !important;
    outline: none;
    text-decoration: none;
  }
`

export default StyledLink
