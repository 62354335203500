import styled from 'styled-components'
import Item from './Item'
import StyledHeader from './StyledHeader'
import StyledLink from './StyledLink'

const FooterList = styled.ol`
  margin-top: 27px;
`

FooterList.StyledHeader = StyledHeader
FooterList.StyledLink = StyledLink
FooterList.Item = Item

export default FooterList
