import styled from 'styled-components'

const StyledHeader = styled.h4`
  color: #25282c;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 3.9px;
  border-bottom: 1px solid #25282c;
  padding-bottom: 9px;
  display: inline-block;
  margin-bottom: 27px;
`

export default StyledHeader
