import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
import styled, { ThemeProvider } from 'styled-components'
import FooterFragment from './fragments/FooterFragment'
import HeaderFragment from './fragments/HeaderFragment'
import GlobalStyles from './globalStyles'

const Container = styled.div`
  min-height: 100vh;
  padding-top: 100px;
  margin-top: -100px;
  max-width: 1500px;
  margin-right: auto;
  margin-left: auto;
`

const theme = {
  breakpoints: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200
  }
}

const Layout = props => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    window.dataLayer = window.dataLayer || []
  }, [])

  return (
    <>
      <Helmet
        meta={[
          {
            name: 'referrer',
            content: 'origin'
          }
        ]}
        link={[
          {
            rel: 'preconnect',
            href: 'https://fonts.gstatic.com',
            crossorigin: true
          },
          {
            rel: 'stylesheet',
            href: 'https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'
          },
          {
            rel: 'stylesheet',
            href: 'https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap'
          }
        ]}
      />
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <HeaderFragment
          {...props}
          show={show}
          setShow={setShow}
        />
        <Container>{props.children}</Container>
        <FooterFragment />
      </ThemeProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
