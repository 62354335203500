import styled, { css } from 'styled-components'

const StyledLinkCSS = css`
  display: inline-block;
  font-size: 11px;
  color: #878787;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: #f1574d !important;
    outline: none;
    text-decoration: none;
  }
`

const StyledLink = styled.a`
  ${StyledLinkCSS}
`

export default StyledLink
