import styled, { css } from 'styled-components'

const fixed = css`
  padding-top: 10px;
  padding-bottom: 17px;
  position: fixed;
  top: 0;
  box-shadow: 0 1px 5px -3px rgba(0, 0, 0, 0.4);
  z-index: 99;
  background-color: rgba(255, 255, 255, 0.9);
  animation-duration: 1s;
  animation-name: fadeInDown;
`

const MovableContainer = styled.div`
  padding: 0 0 17px 0;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100px;
  transition: box-shadow 0.4s;
  ${props => props.fixed && fixed};
  @media screen and (max-width: 768px) {
    align-items: center;
  }
`

export default MovableContainer
