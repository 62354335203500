import styled from 'styled-components'

const Text = styled.p`
  color: #868686;
  font-size: 11px;
  font-weight: 400;
  letter-spacing: 0.44px;
  margin-top: 98px;
  margin-bottom: 0;
`

export default Text
