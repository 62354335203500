import styled from 'styled-components'
import Image from './Image'
import MovableContainer from './MovableContainer'
import StyledLink from './StyledLink'

const Header = styled.header`
  height: 120px;
  width: 100%;
  position: relative;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 768px) {
    height: 80px;
  }
`

Header.Image = Image
Header.MovableContainer = MovableContainer
Header.StyledLink = StyledLink

export default Header
