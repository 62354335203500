import { css } from 'styled-components'

const slickTheme = css`
  .slick-prev,
  .slick-next {
    &:before {
      color: #2c2c2c;
    }
  }
  .slick-dots {
    bottom: 15px;
  }
`

export default slickTheme
