import { faSortDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import { Link } from 'gatsby'
import React, { useContext, useState } from 'react'
import Scroll from 'react-scroll'
import Image from '../../../assets/images/backto.png'
import Logo from '../../../assets/images/logo.png'
import { MobileNavigationContext } from '../../../providers/MobileNavigationProvider'
import Header from '../../blocks/Header'
import NavigationBar from '../../blocks/NavigationBar'
import NavigationDropdown from '../../blocks/NavigationDropdown'
import NavigationMenu from '../../blocks/NavigationMenu'
import ScrollToTopButton from '../../blocks/ScrollToTopButton'
import HamburgerMenu from '../../elements/HamburgerMenu'

const HeaderFragment = props => {
  const [fixed, setFixed] = useState(false)
  const scroll = Scroll.animateScroll

  const { setMobileNavigationStatus, mobileNavigationStatus } = useContext(
    MobileNavigationContext
  )

  useScrollPosition(
    ({ currPos }) => {
      if (!fixed && currPos.y < -150) {
        setFixed(true)
      } else if (currPos.y === 0) {
        setFixed(false)
      }
    },
    [setFixed]
  )

  return (
    <>
      <Header>
        <Header.MovableContainer fixed={fixed}>
          <Header.StyledLink
            onClick={() => setMobileNavigationStatus(!mobileNavigationStatus)}
          >
            <HamburgerMenu />
          </Header.StyledLink>
          <Header.StyledLink as={Link} to='/'>
            <Header.Image src={Logo} alt='Amber Living Logo' height={50} width={148.125} />
          </Header.StyledLink>
        </Header.MovableContainer>
        <NavigationMenu>
          <NavigationMenu.Nav>
            <NavigationBar>
              {
                props.navigation.desktopNavigation.map(navLink => (
                  <NavigationBar.Item key={navLink.id}>
                    <NavigationMenu.Button href={navLink.link[0] === '/' ? navLink.link : '/' + navLink.link}>
                      {navLink.name}
                      {
                        navLink.navigationLinks && (
                          <FontAwesomeIcon icon={faSortDown} />
                        )
                      }
                    </NavigationMenu.Button>
                    {
                      navLink.navigationLinks && (
                        <NavigationDropdown>
                          {
                            navLink.navigationLinks.map(navLink => (
                              <NavigationDropdown.Item key={navLink.id}>
                                <NavigationDropdown.StyledLink
                                  as={Link}
                                  to={navLink.link[0] === '/' ? navLink.link : '/' + navLink.link}
                                >
                                  {navLink.name}
                                </NavigationDropdown.StyledLink>
                              </NavigationDropdown.Item>
                            ))
                          }
                        </NavigationDropdown>
                      )
                    }
                  </NavigationBar.Item>
                ))
              }
              <NavigationBar.Item>
                <NavigationMenu.FlatButton
                  href="https://annabergner.com/einrichtungsberatung-buchen/"
                  target="_blank"
                >
                  Gratis Einrichtungsberatung
                </NavigationMenu.FlatButton>
              </NavigationBar.Item>
            </NavigationBar>
          </NavigationMenu.Nav>
        </NavigationMenu>
      </Header>
      <ScrollToTopButton onClick={() => scroll.scrollToTop()} hidden={!fixed}>
        <ScrollToTopButton.Image src={Image} alt='Arrow up' width={23} height={8} />
      </ScrollToTopButton>
    </>
  )
}

export default HeaderFragment
